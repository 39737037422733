.headerBasics {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // > div {
  //   display: flex;
  //   align-items: center;
  // }

  button.icon {
    border: none;
    margin-right: 20px;
    font-size: 25px;
    .ant-btn-icon .anticon {
      font-size: 30px;
    }
  }
}

.dashboardLayout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  .custom-header {
    @extend .headerBasics;
    @extend .headerBasics;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 80px;
      right: 0;
      border-bottom: 1px solid #c0c0c0;
    }
  }
  .header {
    padding: 0;
    margin-bottom: 30px;
    @extend .headerBasics;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 200px;
      right: 0;
      // border-bottom: 1px solid #c0c0c0;
    }
  }
  .sidebar {
    padding-top: 5px;
    // border-right: 1px solid #c0c0c0;
    .ant-menu {
      border: none !important;
    }
  }
  .content {
    padding: 1.5rem 4rem;
    padding-top: 15px;
    max-height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 576.67px) {
  .dashboardLayout {
    .header {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 80px;
        right: 0;
        border-bottom: 1px solid #c0c0c0;
      }
    }
  }
}
