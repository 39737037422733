//screens-breakpoints
$small-screen: 480px;
$tablet-screen: 768px;
$medium-screen: 1024px;
$large-screen: 1444px;
.skeleton-img {
  width: 100% !important;
}
.ant-col {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
@media (max-width: $small-screen) {
  .ant-card .ant-card-body {
    padding: 8px;
  }
}
//colors
// $white-color: #ffffff;
// $black-color: #5b627d;
// $side-background-color: #6165db6c;
// $main-background-color: #6165DB;
// // F0FBFF
// $green-color: #90ee90;
// $green-color-2: #76ff7a;
// $purple-color: #5d3fd3;
// $light-pink: #ff98ac;
// $gray-color: #eee;
// $red-color: #ff0000;

// //responsive-fonts-function
// @function calculate-font-size($custom-size: null) {
//   $default-size: calc(16px + 0.1vw);

//   @if $custom-size != null {
//     @return $custom-size;
//   } @else {
//     @return $default-size;
//   }
// }

// .headerBasics {
//   padding-left: 15px;
//   padding-right: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: relative;
//   > div {
//     display: flex;
//     align-items: center;
//   }

//   button {
//     border: none;
//     margin-right: 20px;
//     color: #6165DB;
//     font-size: 25px;
//     .ant-btn-icon .anticon {
//       font-size: 30px;
//     }
//   }
// }

.zoom-in-out-animation {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.ant-card {
  border-radius: 20px;
}

// .ant-popover, .ant-dropdown {
//   background-color: rgb(15, 10, 47);
// }
// .ant-popover-content, .ant-dropdown-menu {
//   background-color: linear-gradient(
//     rgba(102, 86, 237, 0.1),
//     rgba(102, 86, 237, 0)
//   );
// }
// .ant-popover-inner {
//   background-color: transparent;
// }

.ant-popover-inner,
.ant-modal-content,
.ant-dropdown-menu,
.ant-card.gradient-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(15, 10, 47, 1) !important;
  background-image: linear-gradient(
    rgba(102, 86, 237, 0.1),
    rgba(102, 86, 237, 0)
  ) !important;
}

.ant-modal-header {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.ant-select-arrow {
  color: rgba(107, 114, 128, 1) !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(107, 114, 128, 1) !important;
}

.anticon-eye-invisible {
  color: black !important;
  font-size: 20px;
}
.anticon-eye {
  color: black !important;
  font-size: 20px;
}

.enabled-switch {
  &.ant-switch {
    background-color: #5a4ccc !important;
  }

  &.ant-switch:hover {
    background-color: #8577e3 !important;
  }
}
